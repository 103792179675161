import React from 'react'
import styles from './ParkingPassCard.module.css'
import { TeamLogo } from 'globalComponents/Logo'
import { MLBTicket } from 'utilities/types'
import TTLinkButton from 'globalComponents/fields/TTLinkButton'
import { Routes } from 'utilities/destinations'
import { classNames, formatDateForTicket } from 'utilities/etc'

interface Props {
  ticket: MLBTicket
  destination?: string | Routes
}

const ParkingPassCard: React.FC<Props> = ({ ticket, destination }) => {
  return (
    <div
      className={classNames(styles.ticketContainer, !destination && styles.bordered)}
      data-forwarded={ticket.ticketForwarded || undefined}
    >
      { ticket.ticketForwarded
        ? <p style={{ marginBottom: '.75rem' }}>Forwarded Parking Pass</p>
        : <p style={{ marginBottom: '.75rem' }}>Parking Pass</p>
      }
      <div className={styles.mainRow}>
        <div>
          <p className={styles.time}>{ticket.publicDescription}</p>
          <p className={styles.time} style={{ marginBottom: '.75rem' }}>{formatDateForTicket(ticket.eventStartTime)}</p>
          <p className={styles.publicDescription}>{ticket.secondaryTitle}</p>
        </div>
        <TeamLogo />
      </div>
      <div>
        { !ticket.ticketForwarded && <p className={styles.parkingInfo}>{ticket.barcode}</p>}
        <p className={styles.parkingInfo}>{ticket.priceScaleDescription}</p>
      </div>
      { destination && !ticket.ticketForwarded &&
         <TTLinkButton
           buttonType={ !ticket.currentlyScanned ? 'primary' : 'secondary' }
           href={destination}
         >
           {ticket.currentlyScanned ? 'Pass already activated' : 'Activate'}
         </TTLinkButton>
      }
    </div>
  )
}

export default ParkingPassCard
