import React, { useContext, useEffect } from 'react'
import destinations from 'utilities/destinations'
import ParkingPassCard from 'globalComponents/ParkingPassCard'
import { useNavigate } from 'react-router-dom'
import { TicketsContext } from 'App'
import TTButton from 'globalComponents/fields/TTButton'
import styles from './index.module.css'
import { DialogTrigger } from 'react-aria-components'
import { TTModal } from 'globalComponents/TTModal'
import SupportSection from 'globalComponents/SupportSection'
import { sortTickets } from 'utilities/etc'

const Parking: React.FC = () => {
  const tickets = useContext(TicketsContext).tickets.sort(sortTickets)
  const navigate = useNavigate()

  useEffect(() => {
    if (tickets.length != 1) return
    const ticket = tickets[0]
    if (ticket.ticketForwarded) return

    if (ticket.currentlyScanned) {
      navigate(destinations.parking.confirmation(ticket.barcode).route())
    } else {
      navigate(destinations.parking.activate(ticket.barcode).route())
    }
  }, [tickets])

  if (!tickets.length) {
    return emptyState()
  } else {
    return passes()
  }

  function emptyState() {
    return (
      <div className={styles.emptyStateContainer}>
        <h1>No passes found</h1>
        <p>We could not find any prepaid parking passes for today’s event.</p>
        <DialogTrigger>
          <TTButton buttonType='primary'>Get Help</TTButton>
          <TTModal title='Help'>
            <SupportSection/>
          </TTModal>
        </DialogTrigger>
      </div>
    )
  }

  function passes() {
    return (
      <>
        <h1>My Parking Passes</h1>
        {
          tickets.map(ticket =>
            <ParkingPassCard
              key={ticket.barcode}
              ticket={ticket}
              destination={
                ticket.currentlyScanned
                  ? destinations.parking.confirmation(ticket.barcode).route()
                  : destinations.parking.activate(ticket.barcode).route()
              }
            />
          )
        }
      </>
    )
  }
}

export default Parking
